import React from "react";
import { useQueryParam } from "gatsby-query-params";
import { graphql, StaticQuery } from "gatsby";

export const Text = ({ children }) => (
  <h3 className="has-text-weight-medium has-text-black is-size-4 has-text-centered">
    {children}
  </h3>
);

export const TextWhite = ({ children }) => (
  <span className="has-text-weight-bold has-text-white">{children}</span>
);

const ApplicationSent = () => (
  <StaticQuery
    query={graphql`
      query AppSentQuery {
        site {
          siteMetadata {
            phone
          }
        }
      }
    `}
    render={(data) => {
      const requestId = useQueryParam("id");
      let phone = data.site.siteMetadata.phone;
      phone = `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6, 9)}`;
      return (
        <section className="section ">
          <div className="container">
            <h1 className="has-text-white is-uppercase is-size-1 has-text-weight-bold has-text-centered">
              Gratulacje
            </h1>
            <Text>Twój wniosek nr</Text>
            <Text>
              <span className="has-text-weight-bold is-size-3">
                {requestId}
              </span>
            </Text>
            <Text>Został pomyślnie wysłany</Text>
            <br />
            <Text>
              <TextWhite>Decyzja Leasingowa</TextWhite> zostanie przesłana na
              podany adres e-mail
            </Text>
            <Text>
              Ten proces może potrwać od{" "}
              <TextWhite>5 minut do 24 godzin</TextWhite>
            </Text>
            <br />
            <Text>
              W tym czasie <TextWhite>doradca leasingowy</TextWhite> może kontaktować
              się z państwem na podany numer telefonu
            </Text>
            <br />
            <Text>
              Jeśli macie Państwo pytania prosimy o kontakt{" "}
              <TextWhite>
                <a
                  href={`tel:+48${data.site.siteMetadata.phone}`}
                  className="has-text-white"
                >
                  {phone}
                </a>
              </TextWhite>
            </Text>
            <br />
            <br />
          </div>
        </section>
      );
    }}
  />
);

export default ApplicationSent;
