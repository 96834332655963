import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const link = "https://leasingpoint.pl/";

const LPBWImage = () => {
  const data = useStaticQuery(
    graphql`
      query LPBWQuery {
        file(relativePath: { eq: "leasingpointBW.png" }) {
          childImageSharp {
            fluid {
              aspectRatio
              sizes
              src
              srcSet
            }
          }
        }
      }
    `
  );
  return <Img fluid={data.file.childImageSharp.fluid} alt="LeasingPointBW" />;
};

const LPFooter = ({ cookiesUsed }) => {
  return (
    <div className="lp-footer">
      <div>Partnerem serwisu w zakresie usług</div>
      <div>finansowych jest</div>
      <div
        className="lp-logo"
        style={{ marginBottom: cookiesUsed ? "0" : "40px" }}
      >
        <a href={link} target="_blank" rel="noopener">
          <LPBWImage />
        </a>
      </div>
    </div>
  );
};

export default LPFooter;
