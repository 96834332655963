import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import image from "../../images/metaImage.png";

export default () => (
  <StaticQuery
    query={graphql`
      query helmetQuery {
        site {
          siteMetadata {
            title
            author
            description
            keywords
          }
        }
      }
    `}
    render={(data) => (
      <Helmet>
        <script type="text/javascript">
          {`var _mtm = _mtm || [];
              _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.type='text/javascript'; g.async=true; g.defer=true; g.src='https://analityka.porowneo.pl/js/container_XyAXZ60U.js'; s.parentNode.insertBefore(g,s);`}
        </script>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta
          property="og:description"
          content={data.site.siteMetadata.description}
        />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <title>{data.site.siteMetadata.title}</title>
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <html lang="en" />
        {/* Google / Search Engine Meta Tags */}
        <meta itemProp="name" content={data.site.siteMetadata.author} />
        <meta itemProp="image" content={image} />
        <meta property="og:image" content={image} />

      </Helmet>
    )}
  />
);
