import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const BackgroundSection = ({ children }) => {
  const data = useStaticQuery(
      graphql`
      query {
        desktop: file(relativePath: { eq: "slide_porowneo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid
  return (
    <BackgroundImage Tag="div" fluid={imageData} className="background-image">
      {children}
    </BackgroundImage>
  );
};

export default BackgroundSection;
