import React from "react";
import { FaGoogle, FaFacebook, FaLinkedin, FaGlobe } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import { TextWhite, Text } from "../ApplicationSent";
import LPFooter from "./LPFooter";

const iconColor = "white";
const iconSize = "3rem";
const socialMediaItems = [
  { id: 1, icon: FaGlobe, name: "companySite" },
  { id: 2, icon: FaFacebook, name: "facebook" },
  { id: 3, icon: FaGoogle, name: "googleMaps" },
  { id: 4, icon: FaLinkedin, name: "linkedIn" },
];

const Footer = ({ cookiesUsed }) => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            googleMaps
            facebook
            linkedIn
            companySite
          }
        }
      }
    `}
    render={(data) => (
      <>
        <footer className="footer center">
          <div className="content has-text-centered">
            <Text>
              <TextWhite>Zachęcamy do odwiedzenia naszej strony</TextWhite>
            </Text>
            <br />
            <div className=" center columns">
              {socialMediaItems.map((item) => {
                return (
                  <>
                    <span className="icon column" key={item.name}>
                      <a href={data.site.siteMetadata[item.name]}>
                        <item.icon size={iconSize} color={iconColor} />
                      </a>
                    </span>
                    &nbsp;
                  </>
                );
              })}
            </div>
            &nbsp;
          </div>
          <br />
        </footer>
        <LPFooter cookiesUsed={cookiesUsed} />
      </>
    )}
  />
);

export default Footer;
