import React from "react";
import Navbar from "../Navbar";
import BackgroundSection from "./BackgorundSection";

const Header = ({ children, homeSite }) => (
  <section className="hero header">
    <Navbar homeSite={homeSite}/>
    <BackgroundSection>
      {children}
    </BackgroundSection>
  </section>
);

export default Header;
