import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Navbar = ({ homeSite }) => {
  let navbarLink = homeSite ? "https://www.porowneo.pl/" : "/";
  const data = useStaticQuery(graphql`
    query LeasingpointIconQuery {
      file(relativePath: { eq: "logo_porowneo.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <div className="hero-head has-background-white">
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <a
              className="navbar-div"
              href={navbarLink}
              target={navbarLink === "/" ? "_self" : "_blank"}
              rel="noopener"
            >
              <div className="image-div">
                <Img
                  fluid={data.file.childImageSharp.fluid}
                  loading="eager"
                  alt="Leasinpoint Icon"
                />
              </div>
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
