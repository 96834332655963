import React, { useEffect, useState } from "react";
import "../style/style.scss";
import Helmet from "../Helmet";
import Header from "../Header";
import Footer from "../Footer";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

const App = ({ children, headerChildren, homeSite }) => {
  const [cookiesUsed, setCookiesUsed] = useState(false);
  useEffect(() => {
    const cookies = getCookieConsentValue("gatsby-gdpr-google-analytics") ? true : false;
    setCookiesUsed(cookies);
  }, []);
  return (
    <div>
      <Helmet />
      <Header homeSite={homeSite}>{headerChildren}</Header>
      {children}
      <Footer cookiesUsed={cookiesUsed} />
      <CookieConsent
        disableStyles={true}
        disableButtonStyles={true}
        location="bottom"
        buttonText="OK"
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="has-background-primary is-size-6 coockies"
        contentClasses="coockies-text"
        buttonWrapperClasses="coockies-wrapper"
        buttonClasses="is-size-6 has-text-white has-background-dark coockies-button"
        onAccept={() => setCookiesUsed(true)}
      >
        Ta strona przetwarza cookies.
      </CookieConsent>
    </div>
  );
};

export default App;
